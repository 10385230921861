import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../../core/components';
import { UserService } from 'src/app/core/services';
import { validateField } from 'src/app/core/helpers/formValidators';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss'],
})
export class RegisterComponent {
  submitted = false;
  fieldTextType: boolean;

  registerForm = this.formBuilder.group(
    {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        // this.validationService.emailValidator,
      ]),
      mobileNumber: new FormControl(null, [
        Validators.required,
        // this.validationService.mobileValidator,
      ]),
      password: new FormControl('', [Validators.required]),
      // confirmPassword: new FormControl('', [Validators.required]),
      role: new FormControl('student', [Validators.required]),
    },
    // {
    //   validator: this.validationService.MustMatch(
    //     'password',
    //     'confirmPassword'
    //   ),
    // }
  );
  validators = {
    firstName: {
      'required': 'First Name is required',
    },
    lastName: {
      'required': 'Last Name is required',
    },
    email: {
      'required': 'Email is required',
      'pattern': 'Invalid email',
    },
    mobileNumber: {
      'required': 'Mobile is required',
    },
    role: {
      'required': 'Role is required',
    },
    password: {
      'required': 'Password is required',
    },
  };

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private toastService: ToastrService,
    public UserService: UserService

  ) { }


  ngOnInit(): void {

    // this.changePwdForm();
    // this.signIn();
  }
  get f() {
    return this.registerForm.controls;
  }

  navigateTo(page: string) {
    this.router.navigate([`${page}`]);
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // register() {
  //   this.submitted = true;
  //   console.log("this.registerForm.value",this.registerForm.value);

  //   if (this.registerForm.invalid) {
  //     return;
  //   } else {
  //     this.spinner.show();
  //     this.UserService
  //       .register(this.registerForm.value)
  //       .subscribe((success) => {
  //         this.toastService.success('Registration done Successfully');
  //         this.router.navigate(['/login']);
  //         this.spinner.hide();
  //       });
  //   }
  // }
  formError(field: string) {
    console.log("field", field);

    let error = '';
    if (this.f[field]?.errors && this.submitted && (this.f[field].dirty || this.f[field].touched)) {
      let key = Object.keys(this.f[field].errors)[0];
      error = this.validators[field][key];
    }
    console.log("error", error);
    return error;
  }

  signIn() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      validateField(this.registerForm);
      console.log('registerForm Start------->', this.registerForm.value);

      return
    }

    this.spinner.show();

    this.UserService
      .create(this.registerForm.value)
      .subscribe((success) => {
        this.toastService.success('Registration done Successfully');
        this.router.navigate(['/auth/login']);
        this.spinner.hide();
        this.submitted = false;

      });
  }

}
