import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from "../models/user.interface";

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(environment.apiEndpoint + "/users").pipe(
            map((users: any) => {
                return users.data;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    Login(user: User) {
        return this.http.post(environment.apiEndpoint + "auth/login", user).pipe(
            map((res: any) => res),
            catchError(this.handleErrorObservable)
        );
    }
    register(user: User) {
        return this.http.post(environment.apiEndpoint + "auth/register", user).pipe(
            map((res: any) => res),
            catchError(this.handleErrorObservable)
        );
    }
    getById(id: string) {
        return this.http.get<User>(environment.apiEndpoint + "auth/profile/" + id).pipe(
            map((user: any) => {
                return user;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    getAllUsersByRoleCare(obj) {

        return this.http
            .get<User>(environment.apiEndpoint + "/getAllUsersByRole/" + obj.page + "/" + obj.pageSize)
            .pipe(
                map((user: any) => {
                    return user;
                }),
                catchError(this.handleErrorObservable)
            );
    }
    //  search user
    userSearch(searchString: string, role: string) {
        return this.http.get(environment.apiEndpoint + "/userSearch/" + searchString + "/" + role).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    getOBUser() {
        if (localStorage.getItem("OBUser")) {
            const user = JSON.parse(localStorage.getItem("OBUser"));
            return user;
        }
    }

    create(user: User) {
        return this.http.post(environment.apiEndpoint + "auth/register", user).pipe(
            map((res: any) => res),
            catchError(this.handleErrorObservable)
        );
    }

    update(payload, id) {
        return this.http.put<User>(environment.apiEndpoint + "auth/update/" + id, payload).pipe(
            map((user: any) => {
                return user;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    resetPass(payload) {
        return this.http.put<User>(environment.apiEndpoint + "auth/change-password/" + payload.id, payload).pipe(
            map((user: any) => {
                return user;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    setPassword(payload) {
        return this.http.post(environment.apiEndpoint + "auth/set-password", payload).pipe(
            map((user: any) => {
                return user;
            }),
            catchError(this.handleErrorObservable)
        );
    }
    forgotPassword(payload) {
        return this.http.post(environment.apiEndpoint + "auth/forgot-password", payload).pipe(
            map((user: any) => {
                return user;
            }),
            catchError(this.handleErrorObservable)
        );
    }


    delete(_id: string) {
        return this.http.delete(environment.apiEndpoint + "/user/" + _id).pipe(
            map((res: any) => res.data),
            catchError(this.handleErrorObservable)
        );
    }


    private handleErrorObservable(error: HttpErrorResponse) {
        return throwError(error);
    }
}
