import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../../../src/app/core/services/httpApi.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})

export class OpenAiService {
  private readonly BASE_ROUTE = 'resume';
  hide = false;
  activeTab = 'CREATE_RESUME'

  constructor(private http: ApiService) { }

  generateResponse(payload: any) {
    const options = {
      // observe: 'events',
      // responseType: 'text',
      // reportProgress: true,
    };
    return this.http
      .postOption(
        environment.apiEndpoint + this.BASE_ROUTE + '/openai',
        payload,
        options
      )
      .pipe();
  }
  downloadResume(payload: any) {
    const httpOptions = {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    };

    return this.http
      .postOption(
        environment.apiEndpoint + this.BASE_ROUTE + '/download-resume',
        payload,
        httpOptions
      )
      .pipe();
  }

  createResume(payload: any) {
    return this.http
      .post(environment.apiEndpoint + this.BASE_ROUTE + '/', payload)
      .pipe();
  }

  getResume() {
    return this.http.get(environment.apiEndpoint + this.BASE_ROUTE + '/');
  }
  delete(id) {
    return this.http.delete(environment.apiEndpoint + this.BASE_ROUTE + '/' + id);
  }
}
