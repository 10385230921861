import { Component, OnInit } from '@angular/core';
import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { Event, RouterEvent, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OpenAiService } from '@services/open-ai.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet><ngx-spinner></ngx-spinner>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private openaiService:OpenAiService

  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((e: any): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((evt: RouterEvent) => {
      if (evt instanceof NavigationEnd) {
        // console.log(evt.url)
        // console.log(evt);
        if (evt.url.includes('pdf')) {
         this.openaiService.hide = true
        } else {
          this.openaiService.hide = false;
        }
        // if (evt.url.includes('my-resume')) {
        //   return this.openai.activeTab = 'MY_RESUME'
        // } else if (evt.url.includes('profile')) {
        //   return this.openai.activeTab = 'PROFILE'
        // } else {
        //   return this.openai.activeTab = 'CREATE_RESUME'
        // }
      }
    })


  }

}
