import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { UserService } from 'src/app/core/services';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss']
})
export class ForgotpassComponent implements OnInit {

  constructor(
    private router: Router,
    private toastService: ToastrService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  forgetPasswordFrom = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  sendMail() {

    this.userService.forgotPassword(this.forgetPasswordFrom.value).subscribe(success => {
      this.router.navigate(["/auth/login"]);
      this.toastService.success("Please check your email to reset password");
    });
  }

}
