import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ChangepwdComponent } from './auth/changepwd/changepwd.component';
import { ForgotpassComponent } from './auth/forgotpass/forgotpass.component';



export const routes: Routes = [


  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth', children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        component: LoginComponent
      },

      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'change-password',
        component: ChangepwdComponent
      },
      {
        path: 'forgot-pwd',
        component: ForgotpassComponent
      },

    ]
  },
  {
    path: 'layout', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  },

  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
